<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Apoteker'">
          <template v-slot:body>
            <HrForm />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import HrForm from '@/component/human-resource/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'Add',

  components: {
    Card,
    HrForm
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apoteker" },
      { title: "Daftar Apoteker", route: "/human-resource/pharmacist/list" },
      { title: "Tambah Apoteker" },
    ])
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>